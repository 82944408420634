.window-header {
  padding: 14px 20px;
  border-bottom: rgba(0, 0, 0, 0.1) 1px solid;
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.window-header-title {
  max-width: calc(100% - 100px);
  overflow: hidden;

  .window-header-main-title {
    font-size: 20px;
    font-weight: bolder;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    max-width: 50vw;
  }

  .window-header-sub-title {
    font-size: 12px;
  }
}

.window-actions {
  display: inline-flex;
}

.window-action-button:not(:first-child) {
  margin-left: 10px;
}
